.thoughtTable {
    margin-left: 50px;
    margin-top: 100px;
    font-family: arial, sans-serif;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 80%;
}

.privateAdminContainerNavbar {
    display: inline-flex;
    border-bottom: 1px solid black;
}

.privateAdminContainerNavbar > span {
    padding: 5px;
    border-right: 1px solid black;
}

.privateAdminContainerNavbar > span:hover {
    cursor: pointer;
    background-color: #d8d8d8;
}