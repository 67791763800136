.loadingTransitionText {
  text-align: center;
  opacity: 0.5;
  /* color: rgb(200, 200, 200); */
}

.created-panel-inner {
  color: rgb(180, 180, 180);
  text-align: center;
  font-size: 0.7em;
  cursor: pointer;
}
