.settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.settings-container > * {
  margin: 1em;
  width: fit-content;
}

.phoneVerificationFormContainer {
  position: absolute;
  bottom: 0px;
}

.phone-number-container {
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}
.settings-container > button {
  margin: 1em;
  width: fit-content;
}
