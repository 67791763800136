.adminThoughtHeader {
  margin: 30px 0px 30px 0px;
}

.totalMatchesGiantText {
  color: red;
  margin-top: 30px;
  margin-bottom: 40px;
}

.adminThoughtTable {
  table-layout: fixed;
  width: 95%;
  margin-left: 2.5%;
  margin-top: 40px;
}

.adminThoughtTableDisplayTopContainer {
  margin-left: 2.5%;
}

.adminThoughtTableCell {
  border: 1px solid black;
  padding: 10px;
}

.totalMatchesDisplayContainer {
  float: right;
  margin-right: 2.5%;
}

.adminThoughtTableCellEmail {
  overflow-wrap: break-word;
}

.timeThCell {
  width: 8em;
}

.adminThoughtTableDisplayResetButton {
  color: blue;
  cursor: pointer;
  margin-left: 20px;
}

.adminThoughtTableDisplayResetButton:hover {
  text-decoration: underline;
}

.emailThCell {
  width: 300px;
}

.adminThoughtTableCellText {
  word-wrap: break-word;
}

.adminThoughtTableCellInteractive:hover {
  background-color: #ddd;
  cursor: pointer;
}

.adminThoughtTableCheck {
  width: 25px;
  text-align: center;
}

.deleteThCell {
  width: 60px;
}

.nameThCell {
  width: 200px;
}

.matchesThCell {
  width: 85px;
}
