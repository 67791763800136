.thoughts-section-container{

}
.threads-filters {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: .9em;
}
.threads-filter-option {
    display: flex;
    margin: 0 .5em;
    cursor: pointer;
    opacity: .5;

}

.threads-filter-option.active {
    text-decoration: underline;
}
.threads-filter-option.unread {
    font-weight: bold;
}
