.chat-container {
    max-width: 42em;
    border-radius: var(--thought-border-radius);
    border: var(--thought-outline);
    padding: .5em;
    margin: 1em;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.chat-header {
    display: flex;
    justify-content: space-between;
}
.chat-editor-container {
    display: flex;
    align-items: center;
    padding: var(--thought-padding);
    border-radius: var(--thought-border-radius);
    border: var(--thought-outline);
    border-color: gainsboro
}
.message {
  display: flex;
}
.message.theirs{
    justify-content: flex-start;
}
.message.mine {
    justify-content: flex-end;

}
.message-inner {
    width: fit-content;
    max-width: 25em;
    /* background-color: rgb(138, 138, 138);
    color: white; */
    padding: 1em;
    padding: var(--thought-padding);
    border-radius: var(--thought-border-radius);
    border: var(--thought-outline);
    margin: 0em 0 0.5em;
}
