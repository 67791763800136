.invite-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    
}
.invite-page-explanation {
    max-width: 36em;
    text-align: center;
    line-height: 2;
    margin: 0 1em;
}

.login-with-google {
    background-color: rgb(198, 216, 255);
    /* box-shadow: 0 0 6px 4px gainsboro; */
    margin: 2em;
    padding: .5em;
    border-radius: .5em;
    cursor: pointer;
    color: blue;
    font-weight: bold;
    /* font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif; */
    /* color: gray; */
}
.login-with-google:active {
    background-color: white;
}
