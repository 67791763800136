.expanded-related-container {
    /* color: blue */
    /* display: flex; */
    flex-direction: column;
    /* commenting out now that an inner piece is scrollable */

    max-height: 60vh;
    overflow-y: auto;
}
.expanded-related-container.principal-thought{
    max-height: none;
}
.related-thoughts-section-label{
    width: 100%;
    text-align: center;
    margin-top: 1em;
    color: gray;

}
.loading-text {
    color: gray;
}
.expanded-filter-tab {
    color: rgb(169, 169, 169); 
    padding: 0 .5em;
    cursor: pointer;
    /* font-style: italic; */
}
.header-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-bottom: 2px rgb(230, 230, 230) solid;
    margin: 0 1.5vh;
    font-size: .9em;
    
    /* width: 100%; */
    

}
.header-row > div {
    padding: .6em .8em;
    cursor: pointer;


}
.header-row > div.selected {
    border-bottom: 3pt solid black;
}
