.mainPageContainer {
  width: Calc(100% - 2em);
  display: flex;
  padding: 1em;
}

.pagePanel {
  margin-right: 1em;
  width: 50vh;
  height: 70vh;
}

.inputWritingHereForm {
  width: 100%;
  height: 60vh;
  font-size: .8em;
  border-radius: 7px;
  background: #dbdbdb;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  line-height: 2;
  color: black;
  border: none;
  font-family: inherit;
  box-sizing: border-box;
  resize: none;
}

.potentialChunkedData {
  background-color: #ebebeb;
  margin: 0em 1em 1em 0em;
  padding: 1em;
  font-size: .8em;
  border-radius: 5px;
  cursor: pointer;
}

.potentialChunkedData:hover {
  background-color: aquamarine;
}

.potentialChunkedDataContainer {
  height: 60vh;
  overflow-y: scroll;
}

.selectedChunkedData:hover {
  background-color: #ff9393;
}

.addCustomThoughtButton {
  text-align: center;
  font-weight: bold;
}

.generateSuggestionsButton {
  position: absolute;
  right: 1em;
  cursor: pointer;
}

.topBarInviterContainer {
  position: relative;
}

.indentedSuggestion {
  margin-left: 1.5em;
  position: relative;
}

.prefixOuterContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}