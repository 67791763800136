.landing-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 1.1em;
  text-align: left;
  /* font-family: "Optima"; */
}
.landing-page-text {
  max-width: 32em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.top-part {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.first-line {
  font-size: 2.2em;
  padding: .5em 0;
}
.landing-subtitle{
  font-size: 1.5em;
}
.enter-plexus {
  /* background-color: lightcoral; */
  /* color: rgb(176, 0, 44); */
  display:flex;
  align-items: center;
  width: fit-content;
  border-radius: var(--thought-border-radius);
  padding: .5em;
  margin: 2em;
  transition: color 1s ease-in, padding 1s ease-in, font-size 1s, background-color 2s, box-shadow 2s;
  cursor: pointer;
  /* padding: 2.2em 0em; */
  outline: none;
  /* border-radius: 50%; */


  /* rgb(255, 43, 85); */
  /* box-shadow: 0 0 16px rgb(211, 211, 211); */
  /* font-weight: bold; */
  color: black(119, 119, 119);
  z-index: 5;
}


.enter-plexus:hover{
/* color: black;
box-shadow: 0 0 100px 100px rgb(0, 0, 0);
background-color: rgb(255, 218, 218); */
/* background-color: black; */
/* color: white; */
/* font-weight: bold; */
text-decoration: underline;

  
  }
.enter-plexus:active{
  color: white;
box-shadow: 0 0 1000px 1000px rgb(0, 0, 0);
background-color: black;
/* background-color: rgb(255, 103, 116); */


/* 0 0 0px 1px rgb(82, 82, 82); */

}
.log-on-text {
  /* color: #fff;  */
  cursor: pointer; 
  text-decoration: underline; 
}

.log-on-text:hover {
  color: black;
}
.link-row > * {
  padding: 1ex;
}

.tech-crunch-button {
  background: #22c022;
  margin-top: 30px;
  padding: 10px 0px;
  font-size: 24px;
  border-radius: 8px; 
  cursor: pointer;
  width: fit-content;
  align-self: center;
  font-weight: bold;
}

.tech-crunch-botton:hover {
  background: #3bb43b
}

.tc-text {
  text-decoration: none;
  padding: 10px 40px;
}
