.chat-preview-container {
    /* color:blue */
    padding: .8em;
    margin: var(--thought-margin);
    outline: var(--thought-outline);
    border-radius: var(--thought-border-radius);
    cursor: pointer;
}
.chat-preview-container.unread {
    font-weight: bold;
}
.person-preview-header {
  
}
.latest-message-preview {
    opacity: .5;
    margin-top: .5em;

}
