.focused-thought-bottom-tabs-container {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.focused-thought-tab-text {
    /* padding: 0.2em; */
    /* border-radius: 3px; */
    cursor: pointer;
    color: grey;
    user-select: none;
    margin-right: .5em;
    display: flex;
    align-items: center;
    cursor: pointer


}

.focused-thought-replies:hover {
    /* text-decoration: underline; */
}


.focused-thought-tab-text:hover {
    color: rgb(63, 63, 63);
  }
