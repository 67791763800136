.detailedViewSidebar {
  width: 20vh;
  height: 100vh;
  background: #61a5eb;
  position: fixed;
}

.menuBarItem {
  text-align: center;
  padding: 10px;
  font-weight: bold;
  color: whitesmoke;
  cursor: pointer;
}

.menuBarItem:hover {
  background-color: #4491e1;
}

.detailedViewContainer {
  margin-top: 10px;
  border-top: 1px solid black;
}

.detailedViewMainArea {
  position: fixed;
  width: Calc(100% - 20vh);
  height: 100%;
  overflow-y: scroll;
  left: 20vh;
}

.topBarCollectContainer {
  display: flex;
  justify-content: space-around;
  padding: 20px;
}

.currentTab {
  background-color: #2d48a3;
}

.inviteButtonContainer {
  display: flex;
}

.inviteEmailContainer {
  padding: 10px;
}

.topBarButton {
  padding: 5px;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 5px;
}

.topBarButton:hover {
  background-color: aquamarine;
}

.getterInputField {
  display: block;
  margin: 2em 2em 2em 0em;
  padding: 0.5em;
  width: 40vh;
}

.getterButton {
  border: 1px solid black;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px;
  display: inline-block;
}

.getterContainer {
  margin-left: 20px;
  width: 40%;
  position: absolute;
}

.fetchedContainer {
  position: absolute;
  width: 55%;
  right: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
  border-bottom: 1px solid black;
}

.contentContainer {
  width: 90%;
  background-color: #ebebeb;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.contentContainer:hover {
  cursor: pointer;
  background-color: #e3f9ff;
}

.contentContainerTopRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}

.optionsContainer {
  margin-top: 10px;
}

.advancedOptionsToggle {
  cursor: pointer;
}

.advancedOptionsToggle:hover {
  text-decoration: underline;
}

.numberInput {
  width: 40px;
}

.selectedContent {
  background-color: #c8a9ff;
}

.leftProcessingViewer {
  overflow-y: scroll;
  width: 40%;
}

.selectedContentsColumn {
  margin-bottom: 10px;
}

.dataProcessingContainer {
  display: flex;
  margin-bottom: 200px;
}

.dataProcessingArea {
  width: 58%;
}

.analysisTextArea {
  width: 100%;
  height: 300px;
}

.processingButtonContainer {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.buttonContainer {
  display: inline-flex;
  justify-content: space-between;
  width: 42vh;
}

.contentCopyText {
  border: 1px solid black;
  padding: 5px;
  margin-top: 5px;
  width: 40px;
  text-align: center;
}

.contentCopyText:hover {
  background: #c8a9ff;
  cursor: pointer;
}

.dataProcessingAreaInviter {
  width: 90%;
}

.scrapedDataWrapper {
  border: 1px solid black;
}
