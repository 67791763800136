@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

:root {
  --bubble: 0 0 0.4em 1px rgb(240, 240, 240);
  --super-bubble: 0 0 01em rgb(208, 208, 208);
  --active-pink: hotpink;
  --active-purple: #400bff;
  --thought-border-radius: 4pt;
  --thought-outline: 1pt solid darkgray;
  --thought-margin: 16pt;
  --thought-padding: 7pt;
}

.App {
  word-break: break-word;
  height: 100vh;
  width: 100%;
  font-size: 16px;
  /* font-family: "Inter", "Noto Sans", "Roboto", "Nunito", "Sarabun", "Cambay", "Poppins",
    "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
    "Trebuchet MS", Verdana, sans-serif; */
  -ms-overflow-style: none;  /* IE and Edge */

  /* scrollbar-width: none;  Firefox   */
}

::-webkit-scrollbar {
  /* width: 2;  */
   /* Remove scrollbar space */
  /* background: transparent;  */
   /* Optional: just make scrollbar invisible */
}

.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}
.panel {
  overflow-y: visible;
  transition: width 1s;
}
.secondary-panel {
  padding: 0;
  height: 100%;
  align-items: center;
}

.post-section {
  position: relative;
  z-index: 10;
  margin: var(--thought-margin);
  padding: 0.5em;
  flex: 0;
  flex-basis: auto;
  height: fit-content;
  min-height: 10vh;
  transition: width 0.3s;
  overflow: visible;
  border: var(--thought-outline);
  border-radius: var(--thought-border-radius);
}

.big-panel {
  flex: 1;
  align-items: stretch;
  align-self: center;
  height: 100vh;
  width: 100%;
  max-width: 48em;
}
.big-panel::-webkit-scrollbar {
  display: none;
}
.create-panel {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: fit-content;
}
.centered-gray-text{
  text-align: center; 
 opacity: .5;
  cursor: pointer

  
}
.tab-title{
  color: gray;
  font-size: 1.3em;
  text-align: center;
  padding: 1em;
  /* font-style: italic; */
  
}
