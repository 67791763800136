.first-thought-request-container {
    /* text-align: left; */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0;
}
.first-thought-editor-container {
    text-align: left;
   max-width: 32em;

   width: 90%;
   /* min-height: 10em; */
   /* min-height: 4em;
   outline:var(--thought-outline);
   border-radius: var(--thought-border-radius);
   padding: var(--thought-padding);   margin: var(--thought-margin) */

}
.first-thought-editor-container > * {
    min-height: 20em;
}
.riddle {
    font-size: 1.2em;

}
.nonEmpty {
    opacity: 0
}
.decision-message {
 max-width: 40em;
 text-align: center;
 padding: 1em;
}
.get-oriented {
    font-size: 1.5em;
}
.submission {

    width: 24em;
    border:var(--thought-outline);
    border-radius: var(--thought-border-radius);
    padding: var(--thought-padding);   margin: var(--thought-margin)

}
