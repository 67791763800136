.detail-view {
  border-radius: 0.4em;
}

.feed-cont-cont-cont.suggested  {
  margin: 0.5em 0;
}
.feed-cont-cont-cont.connected-thought , .feed-cont-cont-cont.suggested-thought  {
  margin: .5em;
}
