.login-page {
  font-size: 1.6em;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.login-page .login-button {
  cursor: pointer;
  padding: 0.5em;
  border-radius: 0.4em;
  font-size: 0.8em;
  margin: 1em;
  color: blue;
  background-color: rgb(217, 226, 255);
  /* border: rgb(255, 197, 220) solid 0.2em; */
}
.login-page .login-button:active {
  background-color: black;
  color: white;
}
