.search-feed {
  flex-direction: column;
  width: 100%;
  height: 95vh;
}

.search-feed.detail {
  display: flex;

}
.search-feed.flex {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.toggle {
  cursor: pointer;
}
.simple-button.toggle {
  background-color: transparent;
}
.toggles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: .5em;
}

.option {
  padding :.4em .6em;
  display: inline-block;
}
.toggle:active {
  color: black;
}

.underlined-option {
  text-decoration: underline;
  text-underline-offset: 4px;
}
.selected-option {
  border-bottom: 2px solid rgb(189, 189, 189) !important;
  padding-bottom: 1px;
  opacity: 1;
}


.app-section-header {
  /* text-align: center; */
  margin: 2ex;
  font-size: 1.3em;
  color: rgb(195, 195, 195);
  /* font-weight: bold; */
  font-style: italic;
}

