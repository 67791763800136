.post-button-container {
  text-align: center;
}

.post-button {
  cursor: pointer;
  height: fit-content;
  /* font-style: italic; */
  font-size: .9em;
  padding: 0.4em;
  border-radius: 3pt;
  background-color: rgb(239, 239, 239);
  border: solid 1px gainsboro

}
.post-button.clicked {
  background-color: white;
  font-style: italic
}
.post-button:active {
  background-color: var(--active-pink);
  background-color: white;
  color: black;

}

.announce-button{
  background: none;
  color: white;
  font-size: .4em;
}
