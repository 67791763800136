.reply-input-field-container  {
    cursor: text !important;
    padding: var(--thought-padding);
    margin: 0.5em;
    transition: padding .2s;
    min-height: 1.4em;
    /* width: fit-content; */
    /* min-width: 20em; */
    /* border-radius: 2pt; */
        outline: var(--thought-outline);
        outline-color: rgb(203, 203, 203);
        margin: var(--thought-margin);
        margin-top: var(--thought-padding);
        border-radius: var(--thought-border-radius)

  
  }
  .reply-input-field-container:focus-within{

    /* outline-color: darkgray; */
  }
  
  
  .reply-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
  .reply-submit-button {
    font-family: "Inter";
    background: transparent;
    border: none;
    font-weight: bold;
    opacity: .6;
  }
  .reply-submit-button.empty {
    opacity: .2;
  }
  
  
