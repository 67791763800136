.updates-section-container {
    /* color: blue */
}
.updates-section-toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: gray;
    font-size: .9em;
}
.updates-section-toggle > * {
    margin: .5em .5em 1em;
    cursor: pointer;
}
.selected-toggle-option {
    text-decoration: underline;
}
.suggested-item > .feed-cont-cont-cont {
    margin-top: 0;
}

